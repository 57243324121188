<template>
    <div class="Home">
      <!-- UBT 튜토리얼 체험 head section background picture -->
      <page-header/>
      <section class="uk-section uk-section-small uk-padding-remove">
        <div class="uk-container">
          <div class="uk-height-large uk-cover-container uk-border-rounded">
            <img src="https://picsum.photos/1300/800/?random" alt="Alt img" data-uk-cover>
            <div
              class="uk-overlay uk-overlay-primary uk-position-cover uk-flex uk-flex-center uk-flex-middle uk-light uk-text-center"
            >
              <div data-uk-scrollspy="cls: uk-animation-slide-bottom-small">
                <h3
                  class="uk-margin-top uk-margin-small-bottom uk-margin-remove-adjacent uk-padding-small"
                >
                  UBT Candidate App Tutorial
                  <br>Experience the exam now written in multimedia.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- end UBT 튜토리얼 체험 -->
      <!-- New Section -->
      <!-- <div class="uk-container">
        <section  class="uk-section uk-section-small uk-section-default uk-padding-small" >
          <div class="uk-container">
            <div class="uk-width-1-1">
              <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider>
                <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-5@m">
                  <li  class="uk-padding-small" >
                    <img src="https://www.bhphotovideo.com/images/images1000x1000/savage_66_86_background_paper_86_x_1521729438000_1398052.jpg" width="400" height="800" alt="" id="cardPic">
                    <div class="uk-margin-large-top uk-position-top-center uk-text-center uk-panel"><h3 id="textColor">1-class aid worker<br>
                      written test</h3></div>
                    <div class="uk-padding uk-position-bottom-center uk-text-center uk-panel"><img class="uk-border-circle" src="https://unsplash.it/65/65/?random" alt="" /><p class="uk-margin-remove-top" id="textColor">예당북스</p> </div>
                  </li>
                  <li  class="uk-padding-small" >
                    <img src="https://www.bhphotovideo.com/images/images1000x1000/savage_66_86_background_paper_86_x_1521729438000_1398052.jpg" width="400" height="800" alt="" id="cardPic">

                    <div class="uk-margin-large-top uk-position-top-center uk-text-center uk-panel"><h3 id="textColor">Image 3<br>Medicine
                      exam</h3></div>
                    <div class="uk-padding uk-position-bottom-center uk-text-center uk-panel"><img class="uk-border-circle" src="https://unsplash.it/65/65/?random" alt="" /><p class="uk-margin-remove-top" id="textColor">예당북스</p> </div>
                  </li>
                  <li  class="uk-padding-small" >
                    <img src="https://www.bhphotovideo.com/images/images1000x1000/savage_66_86_background_paper_86_x_1521729438000_1398052.jpg" width="400" height="800" alt="" id="cardPic">
                    <div class="uk-margin-large-top uk-position-top-center uk-text-center uk-panel"><h3 id="textColor">1-class aid worker<br>
                      written test</h3></div>
                    <div class="uk-padding uk-position-bottom-center uk-text-center uk-panel"><img class="uk-border-circle" src="https://unsplash.it/65/65/?random" alt="" /><p class="uk-margin-remove-top" id="textColor">예당북스</p> </div>
                  </li>
                  <li  class="uk-padding-small" >
                    <img src="https://www.bhphotovideo.com/images/images1000x1000/savage_66_86_background_paper_86_x_1521729438000_1398052.jpg" width="400" height="800" alt="" id="cardPic">

                    <div class="uk-margin-large-top uk-position-top-center uk-text-center uk-panel"><h3 id="textColor">Image 3<br>Medicine
                      exam</h3></div>
                    <div class="uk-padding uk-position-bottom-center uk-text-center uk-panel"><img class="uk-border-circle" src="https://unsplash.it/65/65/?random" alt="" /><p class="uk-margin-remove-top" id="textColor">예당북스</p> </div>
                  </li><li  class="uk-padding-small" >
                    <img src="https://www.bhphotovideo.com/images/images1000x1000/savage_66_86_background_paper_86_x_1521729438000_1398052.jpg" width="400" height="800" alt="" id="cardPic">
                    <div class="uk-margin-large-top uk-position-top-center uk-text-center uk-panel"><h3 id="textColor">1-class aid worker<br>
                      written test</h3></div>
                    <div class="uk-padding uk-position-bottom-center uk-text-center uk-panel"><img class="uk-border-circle" src="https://unsplash.it/65/65/?random" alt="" /><p class="uk-margin-remove-top" id="textColor">예당북스</p> </div>
                  </li>
                  <li  class="uk-padding-small" >
                    <img src="https://www.bhphotovideo.com/images/images1000x1000/savage_66_86_background_paper_86_x_1521729438000_1398052.jpg" width="400" height="800" alt="" id="cardPic">

                    <div class="uk-margin-large-top uk-position-top-center uk-text-center uk-panel"><h3 id="textColor">Image 3<br>Medicine
                      exam</h3></div>
                    <div class="uk-padding uk-position-bottom-center uk-text-center uk-panel"><img class="uk-border-circle" src="https://unsplash.it/65/65/?random" alt="" /><p class="uk-margin-remove-top" id="textColor">예당북스</p> </div>
                  </li>
                </ul>
                <a class="uk-position-center-left uk-position-medium" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                <a class="uk-position-center-right uk-position-medium" href="#" uk-slidenav-next uk-slider-item="next"></a>
                <div  class="uk-width-1-1">
                 <ul class="uk-dotnav">
                    <li uk-slideshow-item="0"><a href="#">Item 1</a></li>
                    <li uk-slideshow-item="1"><a href="#">Item 2</a></li>
                    <li uk-slideshow-item="2"><a href="#">Item 3</a></li>
                    <li uk-slideshow-item="3"><a href="#">Item 1</a></li>
                    <li uk-slideshow-item="4"><a href="#">Item 2</a></li>
                    <li uk-slideshow-item="5"><a href="#">Item 3</a></li>
                    <li uk-slideshow-item="6"><a href="#">Item 1</a></li>
                    <li uk-slideshow-item="7"><a href="#">Item 2</a></li>
                </ul>
              </div>
              </div>
            </div>
            <section class="uk-section">
              <div class="uk-container" uk-grid>
                <div class="uk-subnav uk-width-1-1@m" uk-grid>
                  <h2 class="uk-margin-medium-left uk-width-1-1">UBT 평가를 솔루션 비용 없이 준비할 수 있습니다.</h2>
                  <button type="button" class="uk-button uk-button-small uk-button-primary uk-navbar-right">법인회원가입</button>
                </div>
                <a href="" ><img src="../../assets/capture.png" alt="" ></a>
              </div>
            </section>
            <section  id="Border">
              <div  class="uk-container uk-margin-large-left" >
                <h4  class="uk-margin-large-left uk-margin-medium-top" ><b>[자격증 관련 분야 출판사] <br>
                  책을 구매하신 분들께 UBT시험 서비스를 제공해 보세요<br></b>
                  책과 함께 시험코드정보를 인쇄하여, 구매자가 UBT시험<br>
                  을 무료로 서비스 받을 수 있습니다.</h4>
              </div>
            </section>
          </div>
        </section>
      </div> -->
      <page-footer/>
    </div>

</template>

<script>
import PageHeader from '@/components/Homecmp/Header.vue'
import PageFooter from '@/components/Homecmp/Footer.vue'
  export default {
    components:{
      PageHeader,
      PageFooter,
    }
  }
</script>
<style scoped>
  #cardPic{
    width:400px;
    height: 250px;
  }
  #textColor{
    color:black;
  }
  #border{
    border-style: solid;
    border-radius: 10px;
  }
</style>
